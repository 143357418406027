/** @jsx jsx */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from 'theme-ui'


const Header = ({ siteTitle }) => (
  <div sx={{
    }}>
    <h1 sx={{
      fontWeight: 'black',
      textAlign: ['center', 'left'],
      fontSize: [1,3],
    }}>
      <Link to="/">
        {siteTitle}
      </Link>
    </h1>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
