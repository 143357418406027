/** @jsx jsx **/
import { Link } from "gatsby"
import { jsx } from 'theme-ui'



const ListLink = props => (
  <div>
    <Link to={props.to}>{props.children}</Link>
  </div>
)

const Navigation = () => (
  <nav>
    <div sx={{
        // display: 'grid',
        // gridGap: 1,
        '> * + * ': {
          mt: 2,
        }
      }}>
      <ListLink to="/web/">web</ListLink>
      <ListLink to="/writing/">writing</ListLink>
      <ListLink to="/photography/">photography</ListLink>
      <ListLink to="/wood/">wood</ListLink>
    </div>
    <div sx={{mt: 5}}>
      <ListLink to="/test/">test</ListLink>
    </div>
  </nav>
)

export default Navigation