/** @jsx jsx */
import React from "react"
import { jsx } from 'theme-ui'


const Footer = () => (
  <footer sx={{
    color: 'muted',
    fontSize: 0,
    fontStyle: 'italic',
    marginTop: 5,
  }}>
    ©{new Date().getFullYear()} pritchard
  </footer>
)

export default Footer
