/** @jsx jsx */
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { jsx } from 'theme-ui'
import Header from "./header"
import Navigation from "./navigation"
import Footer from "./footer"
import SEO from "./seo"
import PageTitle from "./page-title"
// import { checkPropTypes } from "prop-types"

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div sx={{
        maxWidth: 1024,
        mx: 'auto',
        p: [2, 4, 6],
      }}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div sx={{
        fontFamily: 'body',
        fontSize: 1,
        display: 'flex',
        flexWrap: 'wrap',
        mt: 4,
        }}>
        <aside sx={{
          flexGrow: 1,
          flexBasis: 200,
        }}>
        <Navigation></Navigation>  
        </aside>
        <div sx={{
          flexGrow: 99999,
          flexBasis: 0,
          minWidth: 320,
          mt: [4, 0]
        }}>
          <main>
            <SEO title={props.title} />
            <PageTitle title={props.title} />
            <div sx={{mt:2}}>
              {props.children}
            </div>
          </main>
        <Footer />
        </div>
      </div>
    </div>
  )
}

export default Layout
